<script>
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import mixin from './model-mixin.vue';

export default {
  name: 'model-gltf',
  mixins: [mixin],
  props: {
    lights: {
      type: Array,
      default() {
        return [
          {
            type: 'HemisphereLight',
            position: { x: 0, y: 1, z: 0 },
            skyColor: 0xcfedff,
            groundColor: 0x606a7f,
            intensity: 0.8,
          },
          {
            type: 'AmbientLight',
            color: 0xe3e3e3,
            intensity: 0.7,
          },
          /*
          {
            type: 'DirectionalLight',
            position: { x: -1, y: -1, z: -1 },
            color: 0xe3e3e3,
            intensity: 0.5,
          },
          {
            type: 'DirectionalLight',
            position: { x: 0, y: -1, z: 1 },
            color: 0xe3e3e3,
            intensity: 0.2,
          },
          {
            type: 'DirectionalLight',
            position: { x: 0, y: 1, z: -1 },
            color: 0xe3e3e3,
            intensity: 0.2,
          },
          */
        ];
      },
    },
    gammaOutput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const loader = new GLTFLoader();
    loader.setCrossOrigin(this.crossOrigin);
    loader.setRequestHeader(this.requestHeader);
    return {
      loader,
    };
  },
  methods: {
    load() {
      if (!this.src) return;
      if (this.object) {
        this.wrapper.remove(this.object);
      }
      this.loader.load(this.src, (data) => {
        this.addObject(data.scene);
        this.$emit('on-load');
      }, (xhr) => {
        this.$emit('progress', xhr);
      }, (err) => {
        this.$emit('on-error', err);
      });
    },
  },
};
</script>
